






























import { Component, Mixins, Prop } from 'vue-property-decorator';
import UserDialog from '@/ui/components/organisms/dialogs/UserDialog.vue';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { ROUTE_CREATE_CAMPAIGN } from '@/ui/router/campaign.routes';
import ButtonWithTextAndIcon from '@/ui/components/atoms/buttons/ButtonWithTextAndIcon.vue';
import { ADMIN_TAB_KEY } from '@/ui/constant/tabs.constant';

@Component({
  name: 'AdminViewTitle',
  components: { UserDialog, ButtonWithTextAndIcon },
})
export default class AdminViewTitle extends Mixins(NavigationMixin) {
  @Prop() currentTab: ADMIN_TAB_KEY | undefined;
  protected showDialog = false;

  setDialogState() {
    this.showDialog = !this.showDialog;
  }

  goToCreateCampaign() {
    this.$emit('updateLastSavedParentRouteForEditCampaign', this.$route.name);
    this.$store.commit(AdminStoreMutations.SET_IS_ADMIN_CREATE_CAMPAIGN, true);
    this.goTo(ROUTE_CREATE_CAMPAIGN);
  }
}
