











































import { Component, Mixins } from 'vue-property-decorator';
import AppHeader from '@/ui/components/organisms/AppHeader.vue';
import AdminViewTitle from '@/ui/components/molecules/titles/AdminViewTitle.vue';
import { AdminStoreGetters, AdminStoreMutations } from '@/ui/stores/AdminStore';
import { SuccessAlertMixin } from '@/ui/mixins/SuccessAlertMixin';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { ROUTE_ADMIN_CAMPAIGNS } from '@/ui/router/admin.routes';
import { getAdminTabs, tabFromRoute } from '@/ui/constant/tabs.constant';
import { Loader } from '@/ui/util/decorators/loader';
import ButtonWithTextAndIcon from '@/ui/components/atoms/buttons/ButtonWithTextAndIcon.vue';
import i18n from '@/i18n';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { PermanentStoreMutations } from '@/ui/stores/PermanentStore';

@Component({
  name: 'AdminView',
  components: {
    AdminViewTitle,
    AppHeader,
    ButtonWithTextAndIcon,
  },
})
export default class AdminView extends Mixins(SuccessAlertMixin, NavigationMixin) {
  locale: keyof LanguageObject = i18n.i18next.language;

  mounted() {
    if (this.$store.getters[AdminStoreGetters.GET_IS_ADMIN_CREATE_CAMPAIGN]) {
      this.goTo(ROUTE_ADMIN_CAMPAIGNS);
      this.$store.commit(AdminStoreMutations.SET_IS_ADMIN_CREATE_CAMPAIGN, false);
    }
  }

  get lastRefreshAt() {
    const lastRefresh = this.$store.state.adminStore.lastRefresh;
    return lastRefresh ? `${this.$t('Admin.general.last-updated')} ${lastRefresh.toLocaleTimeString(this.locale)}` : '';
  }

  get lastRefresh() {
    return this.$store.state.adminStore.lastRefresh;
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get adminTabs() {
    return getAdminTabs(this.currentUser);
  }

  @Loader
  async refreshByTab() {
    switch (this.currentTab()) {
      case 'USERS':
        await this.$store.dispatch('adminStore/reloadUsers');
        break;
      case 'CAMPAIGN':
        await this.$store.dispatch('adminStore/reloadCampaigns');
        break;
      case 'LABELS':
        await this.$store.dispatch('adminStore/reloadTags');
        break;
      case 'VERSION':
        await this.$store.dispatch('adminStore/reloadVersion');
        break;
    }
  }

  getRoute() {
    return this.$route.name ?? '';
  }

  getTab() {
    return tabFromRoute(this.getRoute());
  }

  currentTab() {
    return this.getTab()?.key;
  }

  currentTabId() {
    return this.getTab()?.id;
  }

  updateLastSavedParentRouteForEditCampaign(routeName: string) {
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_FOR_EDIT_CAMPAIGN, routeName);
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_PARAMS_FOR_EDIT_CAMPAIGN, undefined);
  }
}
